html,
body {
  overflow: hidden;
}

html,
body,
#root {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-basis: 100%;
  height: 100%;
}

.ant-table-filter-dropdown-link {
  color: #a1c6da !important;
}

.ant-notification-notice {
  max-width: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto';
}
#root {
  font-family: 'Roboto';
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-BoldItalic-webfont.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Thin-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';

  src: url('./fonts/Roboto-ThinItalic-webfont.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Light-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-LightItalic-webfont.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Medium-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-MediumItalic-webfont.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}
